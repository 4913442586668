<template>
  <div>
    <div class="modal_header">
      <el-row>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.new_user") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                  @click="save()"
                  :loading="loadingButton"
                  :disabled="loadingButton ? true : false"
                  type="success"
              >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                  $t("message.close")
                }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-form
        ref="form"
        :model="form"
        :rules="rules"
        class="aticler_m"
    >
      <el-row :gutter="20">
        <!-- end col -->
        <el-col :span="24">
          <el-tree
              class="filter-tree"
              :data="data"
              show-checkbox
              node-key="id"
              :filter-node-method="filterNode"
              ref="items"
              @node-click="handleNodeClick"
              :expand-on-click-node="true"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="aaa my-tree-khan">
                <div>{{ data.name }}</div>
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <el-select
                      :placeholder="$t('message.class')"
                      v-model="data.amount_type"
                      @change="changes(node, data)"
                      :disabled="!node.checked"
                      size="small"
                      class="my-kh ml-3"
                  >
                    <el-option
                        v-for="item in classes"
                        :key="item.name"
                        :label="item.name"
                        :value="item.type"
                    ></el-option>
                  </el-select>
                  <input
                      :disabled="!data.amount_type"
                      v-model="data.amount"
                      @change="changes(node, data)"
                      :placeholder="'sum'"
                      class="my-kh el-input-user"
                      size="small"
                  />
                  <el-select
                      :placeholder="$t('message.class')"
                      v-model="data.secondary_amount_type"
                      @change="secondaryChanges(node, data)"
                      size="small"
                      class="my-kh ml-3"
                  >
                    <el-option
                        v-for="item in classes"
                        :key="item.name"
                        :label="item.name"
                        :value="item.type"
                    ></el-option>
                  </el-select>
                  <input
                      v-model="data.secondary_amount"
                      @change="secondaryChanges(node, data)"
                      :placeholder="'sum'"
                      class="my-kh el-input-user"
                      size="small"
                  />
                </div>
              </span>
            </span>
          </el-tree>
        </el-col>
      </el-row>
      <!-- end row -->
    </el-form>
    <!-- end form -->
  </div>
</template>

<script>
import AddRole from "./components/addRole.vue";
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import drawerChild from "@/utils/mixins/drawer-child";
export default {
  mixins: [form, drawerChild],
  data() {
    return {
      DoctorServices: [],
      loadingButton: false,
      loadRole: false,
      roles: "",
      specialties: [],
      data: [],
      classes: [
        {
          type: "percent",
          name: "Процент",
        },
        {
          type: "amount",
          name: "Сумма",
        },
      ],
      services: [],
      form: {},
      dialogVisible: false,
      defaultProps: {
        children: "children",
        label: "label",
      },
      selectedList: [],
    };
  },
  async created() {
    this.form = JSON.parse(JSON.stringify(this.model));
    this.DoctorServices = this.form.doctor_services;
    this.loadModel();
  },
  computed: {
    ...mapGetters({
      model: "users/model",
      rules: "users/rules",
      services_list: "services/relation_list",
      columns: "users/columns",
      spess: "users/spess",
    }),
  },
  methods: {
    ...mapActions({
      servicesRealtionList: "services/relationList",
      BonusUpdate: "users/update_bonus",
    }),
    async afterOpened() {
      const query = { relation: true };
      await this.servicesRealtionList(query)
          .then((res) => {
            this.data = res.data.services;
          })
          .catch((err) => {});

      await this.form.secondary_doctor_services.forEach((serv) => {
        this.data.forEach((child) => {
          processSecondaryChildren(child, serv);
        });
        function processSecondaryChildren(child, service) {
          if (child.id == serv.service_id) {
            child.secondary_amount = service.amount;
            child.secondary_amount_type = service.amount_type;
          }
          if (child.children && child.children.length) {
            child.children.forEach((subChild) => {
              processSecondaryChildren(subChild, service);
            });
          }
        }
        // function setSecondaryChildrenData(child, sub_service) {
        //   child.secondary_amount = sub_service.amount;
        //   child.secondary_amount_type = sub_service.amount_type;
        // }
      });

      await this.form.doctor_services.forEach((serv) => {
        this.data.forEach((child) => {
          processChildren(child, serv);
        });
        function processChildren(child, serv) {
          if (child.id == serv.service_id) {
            setChildrenData(child, serv);
          }
          if (child.children && child.children.length) {
            child.children.forEach((subChild) => {
              processChildren(subChild, serv);
            });
          }
        }
        function setChildrenData(child, service) {
          child.amount = service.amount;
          child.amount_type = service.amount_type;
        }
        if (this.data.find((x) => x.id == serv.service_id)) {
          this.data.find((x) => x.id == serv.service_id).amount = serv.amount;
          this.data.find((x) => x.id == serv.service_id).amount_type =
              serv.amount_type;
        }

        function checkAllItems(node, serviceId) {
          if (node && node.childNodes) {
            node.childNodes.forEach((item) => {
              if (item.data.id === serviceId) {
                item.checked = true;
              }

              checkAllItems(item, serviceId);
            });
          }
        }

        if (this.$refs.items) {
          const root = this.$refs.items.root;

          // Check the root node
          if (root && root.childNodes) {
            root.childNodes.forEach((item) => {
              if (item.data.id === serv.service_id) {
                item.checked = true;
              }

              // Recursively check children
              checkAllItems(item, serv.service_id);
            });
          }
        }
      });
    },

    //////////////////////////////////////////////

    changes(node, data) {
      // Update properties for the current node
      data.amount_type = data.amount_type;
      data.amount = data.amount;
      // Recursively update properties for all children
      this.updateChildren(data.children, data.amount_type, data.amount);
    },
    updateChildren(children, amountType, amount) {
      children.forEach((value) => {
        value.amount_type = amountType;
        value.amount = amount;

        // Recursively update properties for all grandchildren
        if (value.children && value.children.length > 0) {
          this.updateChildren(value.children, amountType, amount);
        }
      });
    },
    secondaryChanges(node, data) {
      // Recursively update properties for all children
      this.secondaryUpdateChildren(
          data.children,
          data.secondary_amount_type,
          data.secondary_amount
      );
    },
    secondaryUpdateChildren(children, amountType, amount) {
      children.forEach((value) => {
        value.secondary_amount_type = amountType;
        value.secondary_amount = amount;
        // Recursively update properties for all grandchildren
        if (value.children && value.children.length > 0) {
          this.secondaryUpdateChildren(value.children, amountType, amount);
        }
      });
    },

    //////////////////////////////////////////////

    afterClosed() {},
    save() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.filterDoctorServices();
          var formDataUpdate = new FormData();
          formDataUpdate.append(
              "services",
              JSON.stringify(this.$refs.items.getCheckedNodes())
          );
          this.BonusUpdate({ data: formDataUpdate, id: this.form.id })
            .then((res) => {
              this.loadingButton = false;
              this.form = {};
              this.DoctorServices = [];
              this.$alert(res.message);
              this.$parent.$parent.listChanged();
              this.resetForm("form");
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
                title: "Ошибка",
                type: "error",
                offset: 130,
                message: err.error.message,
              });
            });
        }
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.indexOf(value) !== -1;
      return dd;
    },
    handleNodeClick(node, data, value) {
      this.services = $.grep(this.services, function (e) {
        return e.id != data.data.id;
      });
      this.services.push(data.data);
    },
    selected() {
      this.selectedList = [];
      let item = this.$refs.items.getCheckedNodes();
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          if (element.children.length == 0) {
            this.selectedList.push(element);
          }
        }
      }
    },
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
      this.DoctorServices = this.form.doctor_services;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerUpdate" });
      this.close();
    },
    filterDoctorServices() {
      this.selectedList.forEach((element) => {
        this.DoctorServices.push(element.id);
      });
    },
  },
  components: {
    AddRole,
  },
  watch: {
    model: {
      handler: async function () {
        await this.loadModel();
        // if (this.roles == "") await this.Roles();
        // if (this.specialties_list == []) await this.Specialties();
      },
      deep: true,
    },
    // 'form.branch_ids': function (newPrice) {
    //   console.log(form.branch_ids);
    // }
  },
};
</script>

<style>
input[type="file"] {
  height: 100px;
  font-size: 0;
  -webkit-appearance: none;
  appearance: none;
}

input[type="file"]::-webkit-file-upload-button {
  width: 100px;
  height: 100px;
  opacity: 0;
  -webkit-appearance: none;
  appearance: none;
}

#custom-upload {
  display: inline-block;
}

#custom-upload img {
  height: 70px;
}

.el-input__inner2 {
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 32px;
  line-height: 32px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

.el-input__inner2:disabled {
  background: #f6f8fb;
}

.el-input-user {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.5715;
  color: #606266;
  background-color: #f5f7fa;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* Styling when the input is focused */
.el-input-user:focus {
  outline: 0;
  border-color: #409eff;
  box-shadow: 0 0 0 2px rgba(64, 158, 255, 0.2);
}
</style>